import {
  whiteBase,
  gray1400,
  blue500,
  blue800,
  purple500,
  purple700,
  coral300,
  coral500,
  coral700,
} from './palette';

export const visualizationColors = {
  backgroundColor: whiteBase,
  textColor: gray1400,
  fillColors: [
    blue500,
    coral500,
    blue800,
    purple500,
    coral700,
    coral300,
    purple700,
  ],
  fillTextColors: [
    whiteBase,
    whiteBase,
    whiteBase,
    whiteBase,
    whiteBase,
    whiteBase,
    whiteBase,
  ],
};
